<template>
  <div class="container" :style="{ 'margin-top': statusBarHeight }">
    <div class="top-btn">
      <!-- <el-button type="info" @click="onback" round>返回</el-button> -->
      <div class="back-button" @click="onback">
        <i class="icon-leftarrow"></i>
        <span class="title">返回</span>
      </div>
    </div>

    <div class="center_con"></div>
    <span>{{ loadText }}</span>
    <span>{{ expectText }}</span>
    <span @click="onback" class="back-span">{{ goback }}</span>
  </div>
</template>
<script>
//保留期待頁面
export default {
  components: {},
  data() {
    return {
      loadText: "該功能很快上線，",
      expectText: "敬請期待！",
      goback: "返回上一頁",
      statusBarHeight: "0px",
    };
  },
  methods: {
    //設置返回
    onback() {
      this.$router.go(-1);
    },
  },
  created: function () {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
// 这里是禁止长按选中文字
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.back-span {
  margin-top: 10px;
  color: white;
  text-align: center;
  width: 88%;
  padding: 8px;
  border-radius: 8px;
  height: auto;
  background-color: #54cbb7;
  font-size: 17px;
}
.container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: white;
  color: #828282;
  font-weight: 400;
  flex-direction: column;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-button {
  width: 100%;
  border-radius: 2px;
  height: auto;
  color: white;
  font-size: 26px;
}
.top-btn {
  height: auto;
  width: 100%;
  padding: 15px;
  background-color: #60c5a7;
  position: absolute;
  top: 0;
}
.center_con {
  text-align: center;
  width: 89%;
  height: 204px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/img/reservation-complete.png");
  margin: 10px;
}
</style>